import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="mb-4">
    <div className="container mx-auto pt-4 pb-1">
      <div className="flex flex-col items-center justify-center mx-1">
        <h1 className={`w-full mb-2 text-center `}>
          <Link title="Return to the homepage / list of all blog posts" to="/"
            className="text-black text-header font-bold text-3xl">
              Matt Langeman
          </Link>
        </h1>
        <nav className={`main-menu mb-1 md:mb-4`}>
          <div>
            <Link to="/" title="Read more about me" className="text-gray-700 font-bold uppercase p-2 mx-1 md:mx-2" activeClassName="active">
              Home
            </Link>
            <Link to="/portfolio/" title="View all of my projects" className="text-gray-700 font-bold uppercase p-2 mx-1 md:mx-2" activeClassName="active">
              Portfolio
            </Link>
          </div>
        </nav>
      </div>
    </div>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
